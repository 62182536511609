/* You can add global styles to this file, and also import other style files */
/* styles.css */
/* app-sidenav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 17%;
    z-index: 1000;
}

app-header {
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    height: 60px;
    z-index: 1000;
} */

app-my-vidgets-content {
    width: 100%;
}

app-gallery { 
    min-width: 83.5%;
}

.main-container {
    display: flex;
  }
  
  .sidenav {
    position: fixed;
    width: 250px;
    height: 100vh;
    background-color: #fff;
    z-index: 9;
  }
  
  .content-container {
    margin-left: 250px;
    width: calc(100% - 250px);
    overflow-x: hidden;
  }
  
  .full-width-container {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .sidenav {
      width: 100px;
    }
  
    .content-container {
      margin-left: 100px;
      width: calc(100% - 100px);
    }
  }
  
  @media (max-width: 480px) {
    .sidenav {
      display: none;
    }
  
    .content-container, .full-width-container {
      margin-left: 0;
      width: 100%;
    }
  }
  

app-dashboard {
    width: 100%;
}


/* router-outlet + * {
    margin-top: 60px; 
    margin-left: 250px; 
    padding: 15px; 
} */


body {
    position: relative;
    overflow-x: hidden;
    background-color: #f4f7f7 !important;
}

/* Titles */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter-Bold'
}

p {
    margin-bottom: 0rem !important;
}



.row {
    min-height: 100%;
    height: 100vh;
}

#logoutLink,
#profileLink {
    cursor: pointer;
}

.flex-container {
    display: flex;
    float: right;
    align-items: start;
    /* gap: 1rem; */
    width: 100%;
}




/* app-my-vidgets-content {
    width: 80%;
    background-color: #f4f7f7;
}

app-gallery {
    background-color: #f4f7f7;
} */

.navbar {
    padding: 2rem !important;
    border-bottom: 1px solid var(--gray-300, #D9D9D9);
    background: var(--shades-white, #FFF);
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: #000;
}


.search-bar {
    padding: 0.5rem;
    width: 50%;
    border-radius: 16px;
    border: 1px solid var(--neutral-400, #94A3B8);
    background: var(--shades-white, #FFF);
}

button,
input {
    overflow: visible;
    border: none;
    outline: #fff;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
    z-index: 11000;
    ;
    position: relative;
}

.side-nav {
    border-right: 1px solid var(--gray-300, #D9D9D9);
    background: var(--shades-white, #FFF);
    height: auto;
    border-radius: 0 0 1rem;
    height: fit-content;
}

.side-nav a {
    font-family: 'Inter-Bold';
    color: var(--dark-gray);
    display: flex;
    padding: 1rem 2rem;
    align-items: start;
    gap: 6px;
    align-self: stretch;
    justify-content: start;
    text-align: center;
    width: 100%;
}


.side-nav a:hover {
    text-decoration: none;
}


.side-nav a.active {
    background: #F1F1F1;
    border-right: 10px solid #DD406F !important;

}

#return-home {
    padding: 10px 0px 0px 11px !important;
    font-size: 0.7em;
    text-decoration: underline;
}

#logout {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    position: fixed;
    /* Mantido */
    bottom: 5rem;
    /* Posicionamento fixo, mantido */
    font-size: 0.9em;
    cursor: pointer;
    /* Mantido */
}

#logout img {
    margin-right: 10px;
}

#logout-dropdown {
    display: none;
    padding: 1rem;
    position: fixed;
    /* Alterado para fixed */
    bottom: calc(5rem + 35px);
    /* Ajuste este valor conforme necessário para posicionar corretamente o dropdown */
    left: 150px;
    /* Ajuste baseado na posição do #logout */
    background: white;
    border: none;
    border-radius: 0.8rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    width: 10%;
}


#logout-dropdown a {
    display: flex;
    padding: 10px;
    text-decoration: none;
    color: black;
    gap: 0.3rem;
    align-items: center;
}

#logout-dropdown a:hover {
    background: #f5f5f5;
}


/* main */
.main {
    background-color: #F4F5F7;
}

.col-md-10 {
    padding-left: 0 !important;
}

.content-flex {
    gap: 1rem;
}

input::placeholder {
    font-size: 80%;
}

/* Folders nav */
.folders-section {
    background-color: #fff;
}

.folders-section p {
    font-family: 'Inter-Bold';
}


.folders-section p span {
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    padding: 0.4rem 0.6rem;
}

#createFolder {
    font-family: 'Inter-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem .5rem;
    color: #000;
    background-color: #DFE0E8;
    border-radius: 0.4rem;
    width: 100%;
    margin: 2rem auto;
}

#createFolder:hover {
    background-color: #cdcdcf;
    text-decoration: none;
}

.link-folder {
    color: #000;
    font-family: 'Inter-Regular';
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
}


/* modal snippet */
#snippetModal .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 45%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 1rem;
    padding: 1rem;
    outline: 0;
    margin: 5% auto;
    max-height: 80vh;
}

.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */
}

.modal-content img {
    width: 50%;
    margin: 1rem auto;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.options-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}



/* JS Snippet */
.step-one,
.step-two {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.code {
    font-size: 75%;
}

.step-one span,
.step-two span {
    font-family: 'Inter-Bold';
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    border-radius: 50%;
    background-color: #445EDF;
    color: #fff;
}

.step-two p {
    font-family: 'Inter-Bold';
    color: #000;
    padding: 0 0.25rem;
    font-size: 1.1em;
}

.sub-nav a {
    gap: 6px !important;
}

.copy-card {
    font-family: 'Inter-Bold';
    font-size: 1.1em;
    color: #000;
    padding: 1rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    background-color: #fff;
}

.copy-card p {
    padding: 0 2rem;
    font-size: 0.8em;
    font-family: 'Inter-Regular';
}

.copy-card a {
    color: #000;
}

.titleVideoInput {
    font-size: 1.5em;
}

.titleVideo,
input {
    font-size: 1.4em !important;
}